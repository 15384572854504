var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-row',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"group-by":"order","item-grouped":"","items-per-page":20,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': _vm.$t('table.per-page')
    },"no-data-text":_vm.$t('table.no-data'),"show-select":!_vm.readonly},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('shipments.parcels')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(
            _vm.hasPermission('api.change_shipment') &&
            _vm.selected.length > 0 &&
            _vm.filter == 'available'
          )?_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAddParcels}},[_vm._v(" "+_vm._s(_vm.$t('shipment.parcels.add'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e(),(
            _vm.hasPermission('api.change_shipment') &&
            _vm.selected.length > 0 &&
            _vm.filter == 'added'
          )?_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onRemoveParcels}},[_vm._v(" "+_vm._s(_vm.$t('shipment.parcels.remove'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-minus ")])],1):_vm._e()],1)]},proxy:true},{key:`group.header`,fn:function({ items }){return [_c('th',{staticClass:"secondary",attrs:{"colspan":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.toggleGroup(items[0].order)}}},[_vm._v(" "+_vm._s(_vm.groupStates[items[0].order] ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"group white--text"},[_vm._v(_vm._s(items[0].order))])],1)]}},{key:`item`,fn:function({ item }){return [(_vm.groupStates[item.order])?_c('tr',[_c('td',[_c('v-checkbox',{attrs:{"input-value":_vm.selected.includes(item)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSelection(item)}}})],1),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.pcs))]),_c('td',[_vm._v(_vm._s(item.boxType))]),_c('td',[_vm._v(_vm._s(item.shipment))]),_c('td',[_vm._v(_vm._s(item.location))])]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }