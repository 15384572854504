<template>
  <v-card elevation="0">
    <div class="ma-5">
      <v-btn
        v-if="canAddRemoveParcels()"
        color="primary"
        dark
        class="mb-2 mr-2"
        @click="onScan"
      >
        {{ $t('shipment.parcels.scan-to-add') }}
        <v-icon right dark> mdi-barcode </v-icon>
      </v-btn>
    </div>
    <v-tabs v-model="currentTab">
      <v-tab v-for="item in tabs" :key="item.key" :href="'#tab-' + item.key">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item value="tab-added">
        <v-card-text>
          <parcel-list
            :shipment="shipment"
            filter="added"
            :epoch="epoch"
            :readonly="!canAddRemoveParcels()"
            :current-tab="currentTab"
            tab-value="tab-added"
            @removed="epoch++"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="tab-available">
        <v-card-text>
          <parcel-list
            :shipment="shipment"
            filter="available"
            :epoch="epoch"
            :readonly="!canAddRemoveParcels()"
            :current-tab="currentTab"
            tab-value="tab-available"
            @added="epoch++"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="tab-all">
        <v-card-text>
          <parcel-list
            :shipment="shipment"
            filter="all"
            :epoch="epoch"
            :readonly="!canAddRemoveParcels()"
            :current-tab="currentTab"
            tab-value="tab-all"
          />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <scan
      v-model="scanning"
      :shipment="shipment"
      @close="onEndScanning"
      @added="epoch++"
    />
  </v-card>
</template>

<script>
import ParcelList from './parcelList.vue';
export default {
  components: {
    ParcelList,
    scan: () => import('./scanParcel.vue')
  },
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      currentTab: 'tab-added',

      scanning: false,
      epoch: 0
    };
  },
  computed: {
    tabs() {
      return [
        { key: 'added', name: this.$t('shipment.parcels.added') },
        { key: 'available', name: this.$t('shipment.parcels.available') },
        { key: 'all', name: this.$t('shipment.parcels.all') }
      ];
    }
  },
  watch: {
    currentTab: function () {
      // console.log('currentTab', this.currentTab);
      this.epoch++;
    }
  },
  methods: {
    onScan() {
      this.currentTab = 'tab-available';
      this.scanning = true;
    },
    onEndScanning() {
      this.scanning = false;
    },
    canAddRemoveParcels() {
      if (!this.hasPermission('api.add_shipment')) return false;
      if (this.shipment.status != 'in preparation') return false;

      return true;
    }
  }
};
</script>

<style></style>
