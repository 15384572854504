<template>
  <v-card elevation="0">
    <v-card-title>
      <v-row>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          clearable
        />
      </v-row>
    </v-card-title>
    <v-data-table
      v-model="selected"
      dense
      :headers="headers"
      :items="items"
      :search="search"
      group-by="order"
      item-grouped
      :items-per-page="20"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :show-select="!readonly"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('shipments.parcels') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-btn
            v-if="
              hasPermission('api.change_shipment') &&
              selected.length > 0 &&
              filter == 'available'
            "
            color="primary"
            dark
            class="mb-2 mr-2"
            @click="onAddParcels"
          >
            {{ $t('shipment.parcels.add') }}
            <v-icon right dark> mdi-plus </v-icon>
          </v-btn>
          <v-btn
            v-if="
              hasPermission('api.change_shipment') &&
              selected.length > 0 &&
              filter == 'added'
            "
            color="primary"
            dark
            class="mb-2 mr-2"
            @click="onRemoveParcels"
          >
            {{ $t('shipment.parcels.remove') }}
            <v-icon right dark> mdi-minus </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`group.header`]="{ items }">
        <th colspan="2" class="secondary">
          <v-icon @click="toggleGroup(items[0].order)">
            {{ groupStates[items[0].order] ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          <span class="group white--text">{{ items[0].order }}</span>
        </th>
      </template>

      <template #[`item`]="{ item }">
        <tr v-if="groupStates[item.order]">
          <td>
            <v-checkbox
              :input-value="selected.includes(item)"
              @click.stop="toggleSelection(item)"
            ></v-checkbox>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.pcs }}</td>
          <td>{{ item.boxType }}</td>
          <td>{{ item.shipment }}</td>
          <td>{{ item.location }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ParcelService from '@/services/ParcelService.js';
import ShipmentService from '@/services/ShipmentService.js';

export default {
  props: {
    shipment: {
      type: Object,
      required: true
    },
    filter: {
      type: String,
      required: true
    },
    epoch: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false
    },
    currentTab: {
      type: String,
      required: true
    },
    tabValue: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      items: [],

      selected: [],

      search: '',
      options: {},
      total: 0,
      groupStates: {}
    };
  },
  computed: {
    headers() {
      return [
        {
          text: '#Id',
          value: 'id',
          sortable: true
        },
        {
          text: 'Order',
          value: 'order',
          sortable: true
        },
        {
          text: this.$t('parcel.pcs'),
          value: 'pcs',
          sortable: true
        },
        {
          text: this.$t('parcel.box-type'),
          value: 'boxType',
          sortable: true
        },
        {
          text: this.$t('shipments.shipment'),
          value: 'shipment',
          sortable: true
        },
        {
          text: this.$t('warehouse.item.location'),
          value: 'location',
          sortable: true
        }
      ];
    },
    isActiveTab() {
      return this.currentTab === this.tabValue;
    }
  },
  watch: {
    shipment: {
      handler() {
        if (!this.isActiveTab) return;
        console.log('shipment changed');
        this.loadItems();
      }
    },
    items: {
      handler() {
        console.log('items changed');
        this.initializeGroupStates();
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1;
        console.log('#search');
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    },
    options: {
      handler() {
        if (!this.isActiveTab) return;
        this.loadItems();
      },
      deep: true
    },
    filter() {
      if (!this.isActiveTab) return;
      this.loadItems();
    },
    epoch() {
      if (!this.isActiveTab) return;
      this.loadItems();
    }
  },
  methods: {
    async loadItems() {
      try {
        console.log(
          'loading parcels for shipment.id=',
          this.shipment.id,
          'filter:',
          this.filter
        );
        if (!this.shipment?.id) {
          this.items = [];
          this.total = 0;
          return;
        }

        let f = [];

        switch (this.filter) {
          case 'added':
            f = [
              {
                field: 'shipment_id',
                operation: '=',
                value: this.shipment.id
              }
            ];
            break;
          case 'available':
            f = [
              {
                field: 'order__client__id',
                operation: '=',
                value: this.shipment.client
              },
              {
                field: 'shipment_id',
                operation: '=',
                value: null
              },
              {
                field: 'exclude_blocked',
                operation: '=',
                value: 'true'
              }
            ];
            break;
          case 'all':
            f = [
              {
                field: 'order__client__id',
                operation: '=',
                value: this.shipment.client
              }
            ];
            break;
        }

        [this.items, this.total] = await ParcelService.getItems(
          {
            ...this.options,
            filters: f
          },
          this.search
        );

        // console.log("parcels grouped:", this.parcels)
      } catch (error) {
        console.log('error:', error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async onAddParcels() {
      console.log('onAddParcels:', this.selected);
      let ids = this.selected.map((p) => p.id);
      try {
        await ShipmentService.appendParcels(this.shipment.id, ids);
        await this.loadItems();
        this.selected = [];
        this.$emit('added');
        const shipment = await ShipmentService.getShipment(this.shipment.id);
        this.$root.$emit('shipment_updated', shipment);
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      }
    },

    async onRemoveParcels() {
      console.log('onRemoveParcels:', this.selected);
      let ids = this.selected.map((p) => p.id);
      try {
        await ShipmentService.removeParcels(this.shipment.id, ids);
        await this.loadItems();
        this.selected = [];
        this.$emit('removed');
        const shipment = await ShipmentService.getShipment(this.shipment.id);
        this.$root.$emit('shipment_updated', shipment);
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      }
    },

    initializeGroupStates() {
      let groupStates = this.items.reduce((acc, item) => {
        if (this.groupStates[item.order] == undefined) {
          acc[item.order] = false; // false indicates collapsed
        } else {
          acc[item.order] = this.groupStates[item.order];
        }
        return acc;
      }, {});
      this.groupStates = groupStates;
    },

    toggleGroup(groupName) {
      this.$set(this.groupStates, groupName, !this.groupStates[groupName]);
    },

    toggleSelection(item) {
      const index = this.selected.indexOf(item);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }
    }
  }
};
</script>

<style></style>
